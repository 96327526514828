import React, { useRef, useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import Error from "../../ui/error";
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading"
import { postFormToServer, getFromServer } from "../../global/requests";


const AddPatient = (props) => {
  let navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Yes");
  const [companies, setCompany] = useState();
  const [plan, setPlan] = useState();  
  const [isLoading, setIsLoading] = useState(false);


  const formIsNewRegisterHandler = (e) => {
    setSelectedOption(e.target.value);
  };

  const hospitalid = useRef();
  const firstname = useRef();
  const surname = useRef();
  const phonenumber = useRef();
  const sex = useRef();
  const title = useRef();
  const dob = useRef();
  const barcodecard = useRef();
  const company = useRef();
  const billplan = useRef();
  const companyidnumber = useRef();
  const nextofkin = useRef();
  const address = useRef();
  const stateoforigin = useRef();
  const isnewregistration = useRef();
  const image = useRef();

  const formSubmission = async () => {
    document.getElementById('register').disabled = true
    setIsLoading(true)
    const formData = new FormData();
    let img = "";
    if (image.current.files.length != 0) {
      console.log(img);
      img = image.current.files[0];
    }
    formData.append("hospitalid", hospitalid.current.value);
    formData.append("surname", surname.current.value);
    formData.append("firstname", firstname.current.value);
    formData.append("hospitalid", hospitalid.current.value);
    formData.append("phonenumber", phonenumber.current.value);
    formData.append("company", company.current.value);
    formData.append("billplan", billplan.current.value);
    formData.append("companyidnumber", companyidnumber.current.value);
    formData.append("nextofkin", nextofkin.current.value);
    formData.append("address", address.current.value);
    formData.append("stateoforigin", stateoforigin.current.value);
    formData.append("title", title.current.value);
    formData.append("sex", sex.current.value);
    formData.append("dob", dob.current.value);
    formData.append("isnewregistration", selectedOption);
    formData.append("file", img);

    const result = await postFormToServer(`main/frontdesk/add/patient`, formData);
    if(result.status) {
      setSuccess(true);
      window.scroll(0, 0);
      setIsLoading(false)
      navigate(`/patient-detail/${result.data}?reg_sms_check=1`);
    }
  };

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/company`);
    if (result.status) setCompany(result.data);
  };

  const companyChangeHandler = async (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");

    const result = await getFromServer(`billing/plan/${parseInt(option)}`);
    if (result.status) setPlan(result.data);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (image.current.files.length == 0) {
      formSubmission();
    } else {
      if (String(image.current.files[0].type).includes("image")) {
        setError(false);
        formSubmission();
        // console.log(sex.current.value, title.current.value, dob.current.value)
      } else {
        setError(true);
        window.scrollTo(0, 0);
      }
    }
  };
  const validatePhoneNo = (e) => {
    var x = e.which || e.keycode;
    if (((x >= 48 && x <= 57) || (x >= 96 && x <= 105)) && e.target.value.length < 10) {}
    else if([37, 39, 8, 46].includes(x)) {}
    else e.preventDefault();
    console.log(x);
  }
  return (
    <>
      <MainContent>
        <div className="section-body">
          {error && <Error error="Please Choose only image files !!!" />}
          <Link to="/search-patient" className="btn btn-sm btn-success mb-2">
            Go Back
          </Link>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>Patient Registration</h4>
                  <div className="py-3"></div>
                </div>
                <form
                  method="POST"
                  encType="multipart/form-data"
                  onSubmit={submitHandler}
                >
                  <input type="hidden" name="_token" value="" />
                  <div className="card-body pb-0">
                    <div className="form-group row">
                      <div className="col-md-4">
                        <label>*Hospital ID</label>
                        <input
                          type="text"
                          name="hospital_id"
                          className="form-control"
                          required
                          ref={hospitalid}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>*Title</label>
                        <select className="form-select" ref={title}>
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                          <option value="Master">Master</option>
                          <option value="Chief">Chief</option>
                          <option value="Alhaja">Alhaja</option>
                          <option value="Alhaji">Alhaji</option>
                          <option value="HRM">HRM</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <label>*Surname</label>
                        <input
                          type="text"
                          name="surname"
                          className="form-control"
                          required
                          ref={surname}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-4">
                        <label>*First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          required
                          ref={firstname}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>*Company</label>
                        <select
                          className="form-select"
                          ref={company}
                          onChange={companyChangeHandler}
                        >
                          {companies &&
                            companies.map((u) => {
                              return (
                                <option id={u.id} value={u.name}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-md-4">
                        <label>*Company Plan</label>
                        <select className="form-select" ref={billplan} required>
                          {plan &&
                            plan.map((u) => {
                              return (
                                <option id={u.id} value={u.name}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-4">
                        <label>*Company ID Number</label>
                        <input
                          type="text"
                          name="company_id_no"
                          className="form-control"
                          required
                          ref={companyidnumber}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>*Phone Number</label>
                        <input
                          type="number"
                          name="phone_number"
                          className="form-control"
                          required
                          ref={phonenumber}
                          onKeyDown={validatePhoneNo}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>*Address</label>
                        <input
                          name="address"
                          className="form-control"
                          required
                          ref={address}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-4">
                        <label>*DOB</label>
                        <input
                          type="date"
                          name="dob"
                          className="form-control"
                          required
                          ref={dob}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>*Sex</label>
                        <select className="form-select" ref={sex}>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <label>State Of Origin</label>
                        <input
                          type="text"
                          name="state_of_origin"
                          className="form-control"
                          ref={stateoforigin}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-4">
                        <label>Next Of Kin Ph. No.</label>
                        <input
                          type="text"
                          name="next_of_kin"
                          className="form-control"
                          ref={nextofkin}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>Capture Image</label>
                        <input
                          type="file"
                          name="capture_image"
                          className="form-control"
                          accept="image/png,image/jpg,image/jpeg"
                          ref={image}
                        />
                      </div>

                      {/* <div className="col-md-4">
                        <label>*Is It New Registration</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="No"
                            name="isnew"
                            id="flexRadioDefault1"
                            onChange={formIsNewRegisterHandler}
                            checked={selectedOption === "No"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            No
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="Yes"
                            name="isnew"
                            id="flexRadioDefault2"
                            onChange={formIsNewRegisterHandler}
                            checked={selectedOption === "Yes"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Yes
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {isLoading && <Loading />}
                  <div className="card-footer pt-0">
                    <button type="submit" className="btn btn-primary" id="register">
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default AddPatient;
