require("datejs");

const dt = new Date();
const diffTZ = - dt.getTimezoneOffset();
const zoneHours = Math.floor(diffTZ / 60);
const zoneMinutes = diffTZ % 60;

// --------------- time zons ---------------
const fromatToDateTime = (datetimeStr) => {
  if(datetimeStr && datetimeStr != '')
    return Date.parse(datetimeStr.split('.')[0]).add({ hours: zoneHours, minutes: zoneMinutes }).toString("ddd, MMM d, yyyy HH:mm:ss");
  else return '--';
}
const formatToDate = (datetimeStr) => {
  if(datetimeStr && datetimeStr != '')
    return Date.parse(datetimeStr.split('.')[0]).add({ hours: zoneHours, minutes: zoneMinutes }).toString("dd-MMM-yyyy");
  else return '--';
}
// --------------- dates ---------------
const currDateYYYYMMDD = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

// --------------- string to hrml ---------------
const strMsgToHtmlMsg = (msg) => msg.replace(/(?:\r\n|\r|\n)/g, '<br />');

// --------------- date between ---------------
const checkDateBW = (dateFrom, dateTo, dateCheck) => {
  const d1 = dateFrom.split("-");
  const d2 = dateTo.split("-");
  const c = dateCheck.split("-");

  const from = new Date(d1[0], parseInt(d1[1])-1, d1[2]);  // -1 because months are from 0 to 11
  const to   = new Date(d2[0], parseInt(d2[1])-1, d2[2]);
  const check = new Date(c[0], parseInt(c[1])-1, c[2]);

  return check >= from && check <= to;
}

export {
  fromatToDateTime,
  formatToDate,
  strMsgToHtmlMsg,
  currDateYYYYMMDD,
  checkDateBW,
}