import React, { useState, useEffect } from "react";
import Header from "./components/layout/header";
import Sidebar from "./components/layout/sidebar";
import Footer from "./components/layout/footer";
import Dashboard from "./components/pages/Dashboard/Dashboard"
import SearchPatient from "./components/pages/FrontDesk/SearchPatient";
import AddPatient from "./components/pages/FrontDesk/AddPatient";
import { Route, Routes, Link, Outlet } from "react-router-dom";
import PatientDetail from "./components/pages/FrontDesk/PatientDetail";
import Error404 from "./components/ui/error404";
import Appointments from "./components/pages/FrontDesk/Apponitments";
import Uplaod from "./components/pages/FrontDesk/Upload";
import ViewUploadedFile from "./components/pages/FrontDesk/ViewUplodedFile";
import UserMgt from "./components/pages/UserMgt/UserMgt";
import UserAccessMgt from "./components/pages/UserMgt/UserAccessMgt";
import Authorization from "./components/pages/Billing/Authorization";
import Service from "./components/pages/Billing/Service";
import CompanyMgt from "./components/pages/Billing/CompanyMgt";
import Plans from "./components/pages/Billing/Plans";
import PriceTariff from "./components/pages/Billing/PriceTariff";
import Activation from "./components/pages/FrontDesk/Activation";
import Encounter from "./components/pages/FrontDesk/Encounter";
import ViewEncounter from "./components/pages/FrontDesk/ViewEncounter";
import Outpatient from "./components/pages/Doctor/OutpatientSearch";
import DrugManagement from "./components/pages/Pharmacy/DrugManagement";
import Consumables from "./components/pages/Pharmacy/Consumables";
import Inventory from "./components/pages/Pharmacy/Inventory";
import StoreManagement from "./components/pages/Pharmacy/StoreManagement";
import Supplier from "./components/pages/Pharmacy/Supplier";
import PurchaseOrder from "./components/pages/Pharmacy/PurchaseOrder";
import RecievedItems from "./components/pages/Pharmacy/RecievedItems";
import OpdDispensary from "./components/pages/Dispensary/OpdDispensary";
import DispenseDetails from "./components/pages/Dispensary/DispenseDetails";
import EncounterSetup from "./components/pages/AdminMgt.js/EncounterSetup";
import Vital from "./components/pages/Nursing/Vitals";
import VitalDetails from "./components/pages/Nursing/VitalDetail";
import AdmissionMgtSearch from "./components/pages/AdmissionMgt/AdmissionMgtSearch";
import DrugChart from "./components/pages/AdmissionMgt/DrugChart";
import AdmssionMgt from "./components/pages/AdmissionMgt/AdmissionMgt";
import FluidChart from "./components/pages/AdmissionMgt/FluidChart";
import NursingCare from "./components/pages/AdmissionMgt/NursingCare";
import Immunization from "./components/pages/Nursing/Immunization";
import ImmunizationRecord from "./components/pages/Nursing/ImmunizationRecord";
import VaccineMgt from "./components/pages/UserMgt/VaccineMgt";
import Antenatal from "./components/pages/Nursing/Antenatal";
import Pregnancy from "./components/pages/Nursing/Pregnancy";
import PregnancyDeliveryRecord from "./components/pages/Nursing/PregnancyDeliveryRecord";
import PregnancyBirthDetail from "./components/pages/Nursing/PregnancyBirthDetail";
import PregnancyAntenatalDetail from "./components/pages/Nursing/PregnancyAntenatalDetail";
import Injection from "./components/pages/Nursing/Injection";
import Admission from "./components/pages/Nursing/Admission";
import AdmssionAfterSelect from "./components/pages/Nursing/AdmissionAfterSelect";
import InjectionDetails from "./components/pages/Nursing/InjectionDetail";
import Dressing from "./components/pages/Nursing/Dressing";
import DressingDetails from "./components/pages/Nursing/DressingDetail";
import PharmacyRequisition from "./components/pages/Pharmacy/Requisition";
import NursingRequisition from "./components/pages/Nursing/Requisition";
import BillingCategory from "./components/pages/Billing/BillingCategory";
import AppsPriceTariff from "./components/pages/Apps/AppsPriceTariff";
import EncounterHistory from "./components/pages/FrontDesk/EncounterHistory";
import InpatientDispensary from "./components/pages/Dispensary/InpatientDispensary";
import DispensaryRequisition from "./components/pages/Dispensary/Requisition";
import Vendors from "./components/pages/Pharmacy/Vendors";
import DrugExpiryReport from "./components/pages/Pharmacy/DrugExpiryReport";
import AuthPage from "./components/pages/Billing/AuthPage";
import TestCategory from "./components/pages/Laboratory/TestCategory";
import TestAdmin from "./components/pages/Laboratory/TestAdmin";
import RadiologyTestAdmin from "./components/pages/Radiology/TestAdmin";
import TestReportingParameter from "./components/pages/Laboratory/TestReportingParameter";
import RadiologyTestReportingParameter from "./components/pages/Radiology/TestReportingParameter";
import Investigation from "./components/pages/Doctor/Investigation";
import LaboratoryTestReport from "./components/pages/Laboratory/LaboratoryTestReport";
import LabViewTestReport from "./components/pages/Laboratory/LabViewTestReport";
import LaboratoryResult from "./components/pages/Laboratory/LaboratoryResult";
import EditLaboratoryTestResult from "./components/pages/Laboratory/EditLaboratoryTestResult";
import LabPendingRequest from "./components/pages/Laboratory/LabPendingRequests";
import ECGPendingRequest from "./components/pages/Radiology/ECGPendingRequests";
import XrayPendingRequest from "./components/pages/Radiology/XrayPendingRequests";
import UltrasoundPendingRequest from "./components/pages/Radiology/UltrasoundPendingRequests";
import CTscanPendingRequest from "./components/pages/Radiology/CTscanPendingRequests";
import RadiologyResult from "./components/pages/Radiology/RadiologyResult";
import CashierPoint from "./components/pages/Cashier/CashierPoint";
import CashierDetail from "./components/pages/Cashier/CashierDetail";
import ReviewBills from "./components/pages/Billing/ReviewBills";
import ReviewDetail from "./components/pages/Billing/ReviewDetail";
import BillPage from "./components/pages/Cashier/BillPage";
import Expenses from "./components/pages/Cashier/Expenses";
import Deposit from "./components/pages/Cashier/Deposit";
import DailyCashierReport from "./components/pages/Cashier/DailyCashierReport";
import DischargePatient from "./components/pages/UserMgt/DischargePatient";
import EditPatient from "./components/pages/FrontDesk/EditPatient";
import ApprovalReport from "./components/pages/Billing/ApprovalReport";
import BillSummary from "./components/pages/Billing/BillSummary";
import DiagnosisRequisition from "./components/pages/Laboratory/DiagnosisRequisition";
import DispensaryInventory from "./components/pages/Dispensary/DispensaryInventory";
import NursingInventory from "./components/pages/Nursing/NursingInventory";
import DiagnosisInventory from "./components/pages/Laboratory/DiagnosisInventory";
import Login from "./components/pages/auth/Login";
import SmsCompose from "./components/pages/sms/SmsCompose";
import ShowNoti from "./components/pages/notifications/ShowNoti";
import SmsHistory from "./components/pages/sms/SmsHistory";
import SmsSettings from "./components/pages/sms/SmsSettings";
import {getUserAccessToken, initiateAuth} from "./components/global/auth";
import Protect from "./components/route-filters/Protect";
import SettingSidebar from "./components/ui/SettingSidebar";
import { useNavigate } from "react-router-dom";
import ChatRoom from "./components/pages/Apps/ChatRoom";
import { loadChats, connectToWebSocket } from "./components/global/ws";
import DbBackup from "./components/pages/AdminMgt.js/DbBackup";

import LabResultReport from "./components/pages/reports/LabResultReport";
import AppointmentReport from "./components/pages/reports/AppointmentReport";
import DiagnosisReport from "./components/pages/reports/DiagnosisReport";
import DrugInventoryReport from "./components/pages/reports/DrugInventoryReport";
import ConsumableInventoryReport from "./components/pages/reports/ConsumableInventoryReport";
import AdmissionDischargeReport from "./components/pages/reports/AdmissionDischargeReport";
import PatientSmsHistoryReport from "./components/pages/reports/PatientSmsHistoryReport";
import PaymentReport from "./components/pages/reports/PaymentReport";
import EncounterReport from "./components/pages/reports/EncounterReport";
import DispensaryReport from "./components/pages/reports/DispensaryReport";
import RevenueReport from "./components/pages/reports/RevenueReport";
import WardReport from "./components/pages/reports/WardReport";
import AntenatalReport from "./components/pages/reports/AntenatalReport";
import PregnancyReport from "./components/pages/reports/PregnancyReport";
import BirthReport from "./components/pages/reports/BirthReport";
import ImmunizationReport from "./components/pages/reports/ImmunizationReport";
import EncounterCountReport from "./components/pages/reports/EncounterCountReport";
import LabTestCountReport from "./components/pages/reports/LabTestCountReport";
import RadiologyTestCountReport from "./components/pages/reports/RadiologyTestCountReport";
import RegisterationReport from "./components/pages/reports/RegisterationReport";
import ExpensesReport from "./components/pages/reports/ExpensesReport";
import PurchaseOrderReport from "./components/pages/reports/PurchaseOrderReport";
import UserLoginLogsReport from "./components/pages/reports/UserLoginLogsReport";

import AccessDeniedModel from "./components/modals/auth/AccessDenied";


var firstLoad = true;

function App() {
  // variables
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  // variables related to websocket connection
  const [chatLoading, setChatLoading] = useState(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [newChat, setNewChat] = useState(0);
  // const [userPermissions, setUserPermissions] = useState({});
  const [chatUser, setChatUser] = useState({});
  const [showAccessDenied, setShowAccessDenied] = useState(false);

  // on first load
  useEffect(() => {
    if(firstLoad) {
      firstLoad = false;
      window.reactNavigate = navigate;
      window.setIsLoggedIn = setIsLoggedIn;
      window.isLoggedIn = isLoggedIn;
      window.setWsConnected = setWsConnected;
      window.setNewChat = setNewChat;
      window.newChat = newChat;
      window.setChatUser = setChatUser;
      window.openAccessDenied = () => setShowAccessDenied(true);
      initiateAuth();
      if(getUserAccessToken()) {
        setIsLoggedIn(true);
        loadChats(setChatLoading);
        window.isLoggedIn = isLoggedIn;
        // connectToWebSocket(setWsConnected);
      };
    }
    // console.log(getPermission('testing_key'));
  }, []);

  return (
    <>
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <Header isLoggedIn={isLoggedIn} newChat={newChat} />
          <Sidebar isLoggedIn={isLoggedIn} />
          <AccessDeniedModel show={showAccessDenied} close={() => setShowAccessDenied(false)} />
          <Routes>
            {/* <Route index element={<Dashboard />} /> */}
            <Route index element={<Protect element={<Dashboard />} permission="no_permission" />} exact />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setChatLoading={setChatLoading} setWsConnected={setWsConnected} />} />

            <Route path="/search-patient" element={<Protect element={<SearchPatient />} permission="search_patient_&_register" />} />
            <Route path="/add-patient" element={<Protect element={<AddPatient />} permission="search_patient_&_register" />} />
            <Route path="/edit-patient" element={<Protect element={<EditPatient />} permission="search_patient_&_register" />} />
            <Route path="/patient-detail/:pid" element={<Protect element={<PatientDetail />} permission="search_patient_&_register" />} />
            <Route path="/appointments" element={<Protect element={<Appointments />} permission="appointment_page" />} />
            <Route path="/upload" element={<Protect element={<Uplaod />} permission="upload_file_page_&_download_file_&_delete_file" />} />
            <Route path="/encounter-history" element={<Protect element={<EncounterHistory />} permission="encounter_history_page" />} />
            <Route path="/view-upload/:pid" element={<Protect element={<ViewUploadedFile />} permission="upload_file_page_&_download_file_&_delete_file" />} />
            <Route path="/user-management" element={<Protect element={<UserMgt />} permission="user_management" />} />
            <Route path="/user-access-management" element={<Protect element={<UserAccessMgt />} permission="user_access_management" />} />
            <Route path="/authorization" element={<Protect element={<Authorization />} permission="pending_authorization_&_print_claim_form_&_save" />} />
            <Route path="/service" element={<Protect element={<Service />} permission="service" />} />
            <Route path="/company-management" element={<Protect element={<CompanyMgt />} permission="company_management_&_add_company_&_delete" />} />
            <Route path="/company-management/plan/:id" element={<Protect element={<Plans />} permission="company_management_&_add_company_&_delete" />} />
            <Route path="/drug-management" element={<Protect element={<DrugManagement />} permission="drug_management" />} />
            <Route path="/consumables" element={<Protect element={<Consumables />} permission="consumables" />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/dispensary-inventory" element={<Protect element={<DispensaryInventory />} permission="dispensary_inventory" />} />
            <Route path="/nursing-inventory" element={<Protect element={<NursingInventory />} permission="nursing_review_page_&_save_&_delete" />} />
            <Route path="/diagnosis-inventory" element={<Protect element={<DiagnosisInventory />} permission="inventory" />} />
            <Route path="/store-management" element={<Protect element={<StoreManagement />} permission="store_management" />} />
            <Route path="/supplier" element={<Protect element={<Supplier />} permission="make_purchase_&_confirm_&_delete" />} />
            <Route path="/recieved-items" element={<Protect element={<RecievedItems />} permission="make_purchase_&_confirm_&_delete" />} />
            <Route path="/purchase-order" element={<Protect element={<PurchaseOrder />} permission="make_purchase_&_confirm_&_delete" />} />
            <Route path="/opd-dispensary" element={<Protect element={<OpdDispensary />} permission="opd_and_inpatient_dispensary_&_details_&_dispense_quantity_&_dispense" />} />
            <Route path="/inpatient-dispensary" element={<Protect element={<InpatientDispensary />} permission="opd_and_inpatient_dispensary_&_details_&_dispense_quantity_&_dispense" />} />
            <Route path="/opd-dispense" element={<Protect element={<DispenseDetails />} permission="opd_and_inpatient_dispensary_&_details_&_dispense_quantity_&_dispense" />} />
            <Route path="/encounter-setup" element={<EncounterSetup />} />
            <Route path="/db-backup" element={<Protect element={<DbBackup />} permission="no_permission" />} />
            <Route path="/vitals" element={<Protect element={<Vital />} permission="vitas" />} />
            <Route path="/vital-details" element={<Protect element={<VitalDetails />} permission="vitas" />} />
            <Route path="/billing-category" element={<Protect element={<BillingCategory />} permission="service" />} />
            <Route path="/price-tariff" element={<AppsPriceTariff />} />
            <Route
              path="/admission-mgt/select"
              element={<Protect element={<AdmissionMgtSearch />} permission="admission_mgt" />}
            />
            <Route path="/admission-mgt" element={<Protect element={<AdmssionMgt />} permission="admission_mgt" />} />
            <Route path="/drug-chart" element={<Protect element={<DrugChart />} permission="admission_mgt" />} />
            <Route path="/fluid-chart" element={<Protect element={<FluidChart />} permission="admission_mgt" />} />
            <Route path="/nursing-care" element={<Protect element={<NursingCare />} permission="admission_mgt" />} />
            <Route
              path="/company-management/plan/price/:id"
              element={<PriceTariff />}
            />
            <Route path="/activation/:pid" element={<Activation />} />
            <Route path="/encounter/:pid" element={<Protect element={<Encounter />} permission="search_patient_&_register" />} />
            <Route
              path="/view-encounter/pid=:pid/patient=:ppid"
              element={<Protect element={<ViewEncounter />} permission="search_patient_&_register" />}
            />
            <Route path="/outpatient" element={<Protect element={<Outpatient />} permission="opd_&_opd_admission_&_waiting_list" />} />
            <Route path="/immunization" element={<Protect element={<Immunization />} permission="immunization_page_&_confirm_immunization" />} />
            <Route
              path="/immunization-record/pid=:pid"
              element={<Protect element={<ImmunizationRecord />} permission="immunization_page_&_confirm_immunization" />}
            />
            <Route
              path="/pregnancy-delivery-record/pid=:encounter_pid/:pregnancy_id"
              element={<Protect element={<PregnancyDeliveryRecord />} permission="antenatal_clinic" />}
            />
            <Route
              path="/pregnancy-birth-detail/pid=:encounter_pid/:pregnancy_id"
              element={<Protect element={<PregnancyBirthDetail />} permission="antenatal_clinic" />}
            />
            <Route
              path="/pregnancy-antenatal-detail/pid=:encounter_pid/:pregnancy_id"
              element={<Protect element={<PregnancyAntenatalDetail />} permission="antenatal_clinic" />}
            />
            <Route path="/antenatal" element={<Protect element={<Antenatal />} permission="antenatal_clinic" />} />
            <Route path="/pharmacy-requisition" element={<Protect element={<PharmacyRequisition />} permission="store_requisition" />} />
            <Route path="/nursing-requisition" element={<Protect element={<NursingRequisition />} permission="nursing_requisition_page" />} />
            <Route path="/diagnosis-requisition" element={<Protect element={<DiagnosisRequisition />} permission="consumables_requisition" />} />
            <Route path="/dispensary-requisition" element={<Protect element={<DispensaryRequisition />} permission="requisition" />} />
            <Route path="/injection" element={<Protect element={<Injection />} permission="injection_&_administer_injection" />} />
            <Route path="/injection-detail" element={<Protect element={<InjectionDetails />} permission="injection_&_administer_injection" />} />
            <Route path="/dressing" element={<Protect element={<Dressing />} permission="dressing_&_procedure_&_administer_procedure" />} />
            <Route path="/dressing-detail" element={<Protect element={<DressingDetails />} permission="dressing_&_procedure_&_administer_procedure" />} />
            <Route path="/select-nursing-admission" element={<Protect element={<Admission />} permission="nursing_admission_page" />} />
            <Route path="/patient-admission" element={<Protect element={<AdmssionAfterSelect />} permission="nursing_admission_page" />} />
            <Route path="/pregnancy/pid=:pid" element={<Protect element={<Pregnancy />} permission="antenatal_clinic" />} />
            <Route path="/vaccine-management" element={<Protect element={<VaccineMgt />} permission="vaccine_management" />} />
            <Route path="/vendors" element={<Protect element={<Vendors />} permission="vendors" />} />
            <Route path="/test-category" element={<Protect element={<TestCategory />} permission="test_category" />} />
            <Route path="/laboratory-test-admin" element={<Protect element={<TestAdmin />} permission="test_administration" />} />
            <Route path="/laboratory-test-parameter" element={<Protect element={<TestReportingParameter />} permission="test_reporting_parameter" />} />
            <Route path="/radiology-test-parameter" element={<Protect element={<RadiologyTestReportingParameter />} permission="radiology_test_reporting_parameter" />} />
            <Route path="/radiology-test-admin" element={<Protect element={<RadiologyTestAdmin />} permission="radiology_test_administration" />} />
            <Route path="/drug-expiry" element={<Protect element={<DrugExpiryReport />} permission="confirm_expiry_drugs" />} />
            <Route path="/authorization-detail" element={<Protect element={<AuthPage />} permission="pending_authorization_&_print_claim_form_&_save" />} />
            <Route path="/investigation" element={<Investigation />} />
            <Route path="/lab-result" element={<Protect element={<LaboratoryResult />} permission="laboratory_result_page_&_view_results" />} />
            <Route path="/radiology-result" element={<Protect element={<RadiologyResult />} permission="test_result" />} />
            <Route path="/test-report" element={<Protect element={<LaboratoryTestReport />} permission="pending_request_x-ray" />} />
            <Route path="/lab-pending-request" element={<Protect element={<LabPendingRequest />} permission="pending_request_page_&_enter_result" />} />
            <Route path="/xray-pending-request" element={<Protect element={<XrayPendingRequest />} permission="pending_request_x-ray" />} />
            <Route path="/ultrasound-pending-request" element={<Protect element={<UltrasoundPendingRequest />} permission="pending_request_ultrasound" />} />
            <Route path="/ctscan-pending-request" element={<Protect element={<CTscanPendingRequest />} permission="pending_request_ct_scan" />} />
            <Route path="/ecg-pending-request" element={<ECGPendingRequest />} />
            <Route path="/view-report" element={<LabViewTestReport />} />
            <Route path="/edit-result" element={<EditLaboratoryTestResult />} />
            <Route path="/cashier-point" element={<Protect element={<CashierPoint />} permission="cashier_point_&_make_payment" />} />
            <Route path="/cashier-detail" element={<Protect element={<CashierDetail />} permission="cashier_point_&_make_payment" />} />
            <Route path="/review-bill" element={<Protect element={<ReviewBills />} permission="review_bill_page_&_add_items_&_delete_&_edit" />} />
            <Route path="/review-bill-detail" element={<Protect element={<ReviewDetail />} permission="review_bill_page_&_add_items_&_delete_&_edit" />} />
            <Route path="/bill-page" element={<Protect element={<BillPage />} permission="review_bill_page_&_add_items_&_delete_&_edit" />} />
            <Route path="/expenses" element={<Protect element={<Expenses />} permission="expenses" />} />
            <Route path="/deposit" element={<Protect element={<Deposit />} permission="deposite" />} />
            <Route path="/discharge-patient" element={<Protect element={<DischargePatient />} permission="discharge_patient" />} />
            <Route path="/daily-cashier-report" element={<Protect element={<DailyCashierReport />} permission="daily_cashier_report_page" />} />
            <Route path="/approval-report" element={<Protect element={<ApprovalReport />} permission="approval_report" />} />
            <Route path="/bill-summary" element={<Protect element={<BillSummary />} permission="bill_summary" />} />
            {/* <Route path="*" element={<Error404 />} /> */}
            <Route path="/chat-room" element={<Protect element={<ChatRoom chatLoading={chatLoading} wsConnected={wsConnected} setNewChat={setNewChat} chatUser={chatUser} />} permission="no_permission" />} />
            <Route path="/sms-compose" element={<Protect element={<SmsCompose />} permission="no_permission" />} />
            <Route path="/sms-history" element={<Protect element={<SmsHistory />} permission="no_permission" />} />
            <Route path="/sms-settings" element={<Protect element={<SmsSettings />} permission="no_permission" />} />
            <Route path="/show-notifications" element={<Protect element={<ShowNoti />} permission="no_permission" />} />
            <Route path="/report/lab-result" element={<Protect element={<LabResultReport />} permission="no_permission" />} />
            <Route path="/report/appointment" element={<Protect element={<AppointmentReport />} permission="no_permission" />} />
            <Route path="/report/diagnosis" element={<Protect element={<DiagnosisReport />} permission="no_permission" />} />
            <Route path="/report/drug-inventory" element={<Protect element={<DrugInventoryReport />} permission="no_permission" />} />
            <Route path="/report/consumable-inventory" element={<Protect element={<ConsumableInventoryReport />} permission="no_permission" />} />
            <Route path="/report/admission-discharge" element={<Protect element={<AdmissionDischargeReport />} permission="no_permission" />} />
            <Route path="/report/patient-sms-history" element={<Protect element={<PatientSmsHistoryReport />} permission="no_permission" />} />
            <Route path="/report/payment" element={<Protect element={<PaymentReport />} permission="no_permission" />} />
            <Route path="/report/encounter" element={<Protect element={<EncounterReport />} permission="no_permission" />} />
            <Route path="/report/dispensary" element={<Protect element={<DispensaryReport />} permission="no_permission" />} />
            <Route path="/report/revenue" element={<Protect element={<RevenueReport />} permission="no_permission" />} />
            <Route path="/report/ward" element={<Protect element={<WardReport />} permission="no_permission" />} />
            <Route path="/report/antenatal" element={<Protect element={<AntenatalReport />} permission="no_permission" />} />
            <Route path="/report/pregnancy" element={<Protect element={<PregnancyReport />} permission="no_permission" />} />
            <Route path="/report/birth" element={<Protect element={<BirthReport />} permission="no_permission" />} />
            <Route path="/report/immunization" element={<Protect element={<ImmunizationReport />} permission="no_permission" />} />
            <Route path="/report/encounter-count" element={<Protect element={<EncounterCountReport />} permission="no_permission" />} />
            <Route path="/report/lab-test-count" element={<Protect element={<LabTestCountReport />} permission="no_permission" />} />
            <Route path="/report/radiology-test-count" element={<Protect element={<RadiologyTestCountReport />} permission="no_permission" />} />
            <Route path="/report/registeration" element={<Protect element={<RegisterationReport />} permission="no_permission" />} />
            <Route path="/report/expenses" element={<Protect element={<ExpensesReport />} permission="no_permission" />} />
            <Route path="/report/purchase-order" element={<Protect element={<PurchaseOrderReport />} permission="no_permission" />} />
            <Route path="/report/user-login-logs" element={<Protect element={<UserLoginLogsReport />} permission="no_permission" />} />
          </Routes>
          <SettingSidebar />
        </div>
      </div>
      <div className="position-fixed bottom-0 end-0 p-3 toast-container" style={{zIndex: "11"}} id="popups"></div>
    </>
  );
}

export default App;
